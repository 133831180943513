<template lang="pug">
TableV2(
  :headers="headers"
  :items="items.results"
  :pageCount="items.count"
  :currentPage="items.current"
  :querySearch="getPowersOfAttorneyReport"
  :isLoading="isLoading"
  :saveReport="getPowersOfAttorneyReportExcel"
)
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  data () {
    return {
      headers: [
        { value: 'id', text: 'ID', sortable: false },
        { value: 'created_at', text: this.$t('tableHeaders.created_at'), sortable: false },
        { value: 'number_document', text: this.$t('tableHeaders.number'), sortable: false },
        { value: 'sailor.id', text: this.$t('tableHeaders.sailor_id'), sortable: false },
        { value: 'sailor.sailor_full_name_ukr', text: this.$t('tableHeaders.sailor_full_name'), sortable: false },
        { value: 'sailor.sailor_full_name_eng', text: this.$t('tableHeaders.sailor_full_name_eng'), sortable: false },
        { value: 'manager.id', text: this.$t('tableHeaders.manager_id'), sortable: false },
        { value: 'manager.manager_full_name_ukr', text: this.$t('tableHeaders.manager_full_name'), sortable: false },
        { value: 'manager.manager_full_name_eng', text: this.$t('tableHeaders.manager_full_name_eng'), sortable: false },
        { value: 'status_document', text: this.$t('tableHeaders.status_document'), sortable: false }
      ]
    }
  },
  computed: {
    ...mapState({
      items: (state) => state.report.powersOfAttorney,
      isLoading: (state) => state.report.isLoadingReport
    })
  },
  methods: {
    ...mapActions(['getPowersOfAttorneyReport', 'getPowersOfAttorneyReportExcel'])
  }
}
</script>
